import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const LoginModal = ({ show, onClose, onLoginSuccess }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleClose = () => {
        setFormData({
            email: '',
            password: '',
        });
        onClose();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const apiUrl = 'https://event.magicalproduction.eu//api/login'; // Změňte podle potřeby
            const response = await axios.post(apiUrl, formData);
            const data = response.data;

            if (data.success) {
                onLoginSuccess();
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" name="email" value={formData.email} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" value={formData.password} onChange={handleInputChange} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Log In
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;
