// Sidebar.js
import React, { useEffect, useState } from 'react';
import { Button, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [activeNavItem, setActiveNavItem] = useState(null);  // Změna z 0 na null
    const location = useLocation();  // Použití useLocation pro sledování aktuální cesty

    useEffect(() => {
        // Automaticky vyber položku menu na základě aktuální cesty
        const currentPath = location.pathname;
        const foundIndex = menuItems.findIndex(item => currentPath.includes(item.path));
        if (foundIndex !== -1) {
            handleNavItemSelect(foundIndex);
        }
    }, [location.pathname]);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const handleNavItemSelect = (index) => {
        setActiveNavItem(index);
    };

    const menuItems = [
        { label: 'Můj event', icon: '📅', path: '/myevent' },
        { label: 'Moderátoři', icon: '👥', path: '/moderators' },
        { label: 'Kapely', icon: '🎸', path: '/bands' },
        { label: 'Výzdoba', icon: '🎉', path: '/decoration' },
    ];

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
            <Button variant="light" onClick={toggleCollapse} className="collapsed">
                <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <Nav className={`flex-column ${collapsed ? 'collapsed' : ''}`}>
                {menuItems.map((item, index) => (
                    <Nav.Item key={index}>
                        <Link
                            to={item.path}
                            className={`nav-link ${activeNavItem === index ? 'active' : ''}`}
                            onClick={() => handleNavItemSelect(index)}
                        >
                            <span role="img" aria-label={item.label}>
                                {item.icon}
                            </span>
                            <span className="nav-text">{!collapsed && item.label}</span>
                        </Link>
                    </Nav.Item>
                ))}
            </Nav>
        </div>
    );
};

export default Sidebar;
