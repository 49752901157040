import React, { useState } from 'react';
import { Navbar as BootstrapNavbar, Nav, Image, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEdit, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import LoginModal from './LoginModal';

const Navbar = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLoginClick = () => {
        setShowLogin(true);
    };

    const handleLoginClose = () => {
        setShowLogin(false);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
    };

    return (
        <BootstrapNavbar bg="dark" variant="dark">
            <BootstrapNavbar.Brand>
                <Image src="https://magicalproduction.eu/wp-content/uploads/2023/11/logoMagicalZlateSBilym-09.png" alt="Logo" style={{ maxWidth: '150px', maxHeight: '100px' }} />
            </BootstrapNavbar.Brand>
            <BootstrapNavbar.Toggle />
            <BootstrapNavbar.Collapse className="justify-content-end">
                <Nav>
                    {isLoggedIn ? (
                        <Dropdown show={showLogin} onToggle={handleLoginClick}>
                            <Dropdown.Toggle as={Button} variant="dark" id="user-menu">
                                <FontAwesomeIcon icon={faUser} />
                                &nbsp;Přihlášený uživatel
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <FontAwesomeIcon icon={faEdit} />
                                    &nbsp;Upravit profil
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleLogout}>
                                    <FontAwesomeIcon icon={faSignOutAlt} />
                                    &nbsp;Odhlásit
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <Button variant="dark" onClick={handleLoginClick}>
                            Přihlásit
                        </Button>
                    )}
                </Nav>
            </BootstrapNavbar.Collapse>

            {showLogin && <LoginModal show={showLogin} onClose={handleLoginClose} />}
        </BootstrapNavbar>
    );
};

export default Navbar;
