import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Importuj axios

const DataFetcher = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const apiUrl = process.env.REACT_APP_API_URL || 'default/api/url';

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + `/connect.php`); // Použij axios.get místo fetch
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Chyba při získávání dat:', error);
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Data z API:</h1>
            {loading ? (
                <p>Načítám data...</p>
            ) : (
                <pre>{JSON.stringify(data, null, 2)}</pre>
            )}
        </div>
    );
};

export default DataFetcher;
