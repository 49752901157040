// MyEvent.js
import React from 'react';
import './MyEvent.css'; // Import MyEvent styly

const MyEvent = () => {
    return (
        <div>
            <h1>My Event</h1>
            <p>Toto je obsah komponenty MyEvent.</p>
        </div>
    );
};

export default MyEvent;
