// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Layout from './components/Layout'; // Import nového layoutu
import MyEvent from './components/MyEvent';
import Moderators from './components/Moderators';
import Bands from './components/Bands';
import Decoration from './components/Decoration';
import 'bootstrap/dist/css/bootstrap.min.css';
import DataFetcher from "./DataFetcher";

const App = () => {
    return (

        <Router>
            <div className="app-container">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Layout> {/* Obsah pro hlavní stránku */}</Layout>} />
                    <Route path="/myevent" element={<Layout><MyEvent /></Layout>} />
                    <Route path="/moderators" element={<Layout><Moderators /></Layout>} />
                    <Route path="/bands" element={<Layout><Bands /></Layout>} />
                    <Route path="/decoration" element={<Layout><Decoration /></Layout>} />
                </Routes>
                <DataFetcher />
            </div>
        </Router>
    );
};

export default App;
