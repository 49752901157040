// Layout.js
import React, { useState } from 'react';
import Sidebar from './Sidebar'; // Import Sidebar
import './Layout.css'; // Import styly pro Layout

const Layout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className={`layout-container ${collapsed ? 'collapsed' : ''}`}>
            {/* Sidebar */}
            <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />

            {/* Obsah */}
            <div className="content-container">
                {children}
            </div>
        </div>
    );
};

export default Layout;
